module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://60b4b8d49bf54e52a4426a49897e0fbb@o920220.ingest.sentry.io/5865472","autoSessionTracking":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-tracking/gatsby-browser.js'),
      options: {"plugins":[],"debug":false,"googleAnalytics":{"trackingId":"G-PE5YXY93J4","autoStart":false,"anonymize":false,"controlCookieName":"xqmcn","cookieFlags":"secure;samesite=none"},"facebookPixel":{"pixelId":"985524935427167","controlCookieName":"xqmcn"},"hotjar":{"trackingId":"2895455","snippetVersion":"6","controlCookieName":"xqmcn"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
