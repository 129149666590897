exports.components = {
  "component---src-pages-employer-tsx": () => import("./../../../src/pages/employer.tsx" /* webpackChunkName: "component---src-pages-employer-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kandydat-tsx": () => import("./../../../src/pages/kandydat.tsx" /* webpackChunkName: "component---src-pages-kandydat-tsx" */),
  "component---src-pages-obserwowane-tsx": () => import("./../../../src/pages/obserwowane.tsx" /* webpackChunkName: "component---src-pages-obserwowane-tsx" */),
  "component---src-pages-pracodawca-tsx": () => import("./../../../src/pages/pracodawca.tsx" /* webpackChunkName: "component---src-pages-pracodawca-tsx" */),
  "component---src-pages-tsx": () => import("./../../../src/pages/[...].tsx" /* webpackChunkName: "component---src-pages-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-job-tsx": () => import("./../../../src/templates/job.tsx" /* webpackChunkName: "component---src-templates-job-tsx" */),
  "component---src-templates-work-tsx": () => import("./../../../src/templates/work.tsx" /* webpackChunkName: "component---src-templates-work-tsx" */)
}

